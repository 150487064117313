<template>
  <div>
    <vx-card card-background="primary" content-color="white" title-color="white" title="¡Entérate de todo!" class="">
      <div class="vx-col w-full">
        <p>Configura las notificaciones que deseas recibir en el correo electrónico que tienes registrado en la Red conforme a tus intereses.
          Podrás ser notificado con promociones especiales , noticias, cambios relevantes en RedGirasol y más.</p>
      </div>
    </vx-card>

    <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">{{marketingPromotionalContent.name}}</h5>
          <vs-switch v-model="marketingPromotionalUserPref.value" @change="updatePrefs" />
        </div>
        <p class="">{{marketingPromotionalContent.description}}</p>
      </div>
    </vx-card>

    <vx-card class="mt-5">
      <div class="mb-4">
        <div class="flex items-center mb-5">
          <h5 class="mr-5">{{marketingNotificationsContent.name}}</h5>
          <vs-switch v-model="marketingNotificationsUserPref.value" @change="updatePrefs" />
        </div>
        <p class="">{{marketingNotificationsContent.description}}</p>
      </div>
    </vx-card>

  </div>
</template>

<script>
export default {
  name: "NotificationsPage",
  data(){
    return {
      isBusy: false,

      marketingPromotionalContent: {},
      marketingPromotionalUserPref: {value: 0},
      marketingNotificationsContent: {},
      marketingNotificationsUserPref: {value: 0},
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.getNotifsPreferences();
    this.showLoading(false)
  },
  methods: {
    async getNotifsPreferences(){
      try {
        const res = await axios.get(`api/v1/supplier/${this.SupplierId}/getNotificationsPreferences`);
        const unots = res.data.user_notifs;
        const all = res.data.all_notifs;

        this.marketingPromotionalContent = all.find(f => f.event_code == "advertising");
        this.marketingPromotionalUserPref = unots.find(f => f.notification_id === this.marketingPromotionalContent.id) || this.newPref(this.marketingPromotionalContent);
        
        this.marketingNotificationsContent = all.find(f => f.event_code == "notifications");
        this.marketingNotificationsUserPref = unots.find(f => f.notification_id === this.marketingNotificationsContent.id) || this.newPref(this.marketingNotificationsContent);
      }
      catch (e) {
        this.warn(e);
      }
    },
    newPref(notif){
      return {notification_id: notif.id, value: false };
    },
    async savePrefs(){
      this.showLoading(true)
      try {
        let payload = {preferences: this.getPrefsPayload()};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/supplier/${this.SupplierId}/updateNotificationsPreferences`, payload);
        this.successNotif('¡Listo!', 'Tus preferencias han sido actualizadas', 2000);
      }
      catch (e) {
        this.warn(e)
        this.errorNotif('Error en guardado', 'No ha sido posible actualizar la información');
      }
      finally {
        this.showLoading(false)
      }
    },
    getPrefsPayload(){
      let prefs = [];

      prefs.push(this.setPref(this.marketingPromotionalUserPref, null,));
      prefs.push(this.setPref(this.marketingNotificationsUserPref, null,));

      return prefs;
    },
    setPref(pref, details){
      return {notification_id: pref.notification_id, value: pref.value, details: details };
    },
    async updatePrefs(){
      if(this.isBusy){ return false; }
      this.isBusy = true;
      await this.savePrefs();
      this.isBusy = false;
    },
  }
}
</script>