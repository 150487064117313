<template>
  <div>
    <div class="vx-row" v-if="hasError">
      <span class="error_text">Ha ocurrido un error mientras se obtenían los datos. Lamentamos el inconveniente.
        <a href="#" class="underline bold black" @click="reload">Intente recargando la página.</a></span>
    </div>

    <template v-if="!isSupplier && !hasError">

      <div class="vx-row">
        <div class="vx-col w-full">
          <h4>¡Abonar fondos a tu cuenta es muy fácil!</h4>
          <p class="mt-3">
            Envía un SPEI o transferencia electrónica desde tu banca por internet seleccionando el listado de bancos STP
            (Sistema de Transferencias y Pagos)
            a la siguiente cuenta CLABE. Esta cuenta CLABE no cambiará, es tuya y se generó ¡sólo para ti!
          </p>
          <p class="mt-3 bold">
            Considera que deberás ser titular de las cuentas desde donde envíes fondos, ya que de lo contrario, tendremos
            que reembolsar tu abono.
          </p>
          <p class="mt-3">
            Todas las cuentas con las que abones serán registradas para el momento en el que decidas retirar tus fondos.
            Consúltalas en el apartado de “Banco” dentro de “Mi perfil”.
          </p>
        </div>
      </div>

      <div class="vx-row mt-5 mb-6">
        <div class="vx-col w-full">
          <div v-if="hasStpAccount" class="vx-col w-full flex">
            <div class="stp-section-container">
              <span class="sm:mx-8 mx-4 bold">{{ stpClabe }}</span>
            </div>
            <vs-button id="copy_accounts_btn" class="ml-2 pt-3 pb-2 px-3" color="primary" @click.stop="copyToClipboard">
              <feather-icon icon="FileTextIcon" svg-classes="h-9 w-9" />
            </vs-button>
          </div>
          <span v-else class="sm:mx-8 mx-4 bold">Contáctanos a través del chat para generar tu cuenta STP y puedas
            realizar este proceso.</span>
        </div>

        <div class="vx-col w-full mt-4">
          <p class="italic">Puedes copiar tu CLABE personalizada al portapapeles al dar clic en el icono junto a la misma.
          </p>
        </div>
      </div>

      <div class="vx-row mt-3 mb-6" v-if="copied">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <vs-alert :active.sync="copied" color="success" closable>CLABE copiada al portapapeles."</vs-alert>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <h4>¿No sabes cuáles son los pasos a seguir? ¡Te los explicamos!</h4>
        </div>
      </div>

      <div class="vx-row mt-3">
        <div class="vx-col w-full">
          <p class="mbot"><span class="list-number">1</span> Una vez que entres al portal de tu banca en línea o tu app
            de banca móvil, selecciona la opción enviar un SPEI.</p>
          <p class="mbot"><span class="list-number">2</span> En el listado de bancos destino, selecciona <span
              class="bold-inline">STP (Sistema de Transferencias y Pagos).</span></p>
          <p class="mbot"><span class="list-number">3</span> Ingresa la cuenta CLABE personalizada: <span
              class="bold-inline">{{ stpClabe }}</span>.</p>
          <p class="mbot"><span class="list-number">4</span> En el campo “referencia” o “concepto” podrás escribir lo que
            tú consideres.</p>
          <p class="mbot"><span class="list-number">5</span> En el campo “beneficiario”, escribe tu nombre completo.</p>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <p>
            <br>
            <em>
              Ya que hayas realizado el proceso, verás reflejado el monto en tu saldo disponible en un plazo máximo de 2
              horas.
              Si tienes dudas, estamos para ayudarte a través del chat de la plataforma.
            </em>
          </p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="vx-row">
        <div class="vx-col w-full">
          <h4>¡Verificar tu cuenta es muy fácil!</h4>
          <p class="mt-3">
            Envía un SPEI o transferencia electrónica con valor de <strong>$1.00 MXN (un peso)</strong> desde tu banca por internet seleccionando el listado de bancos STP
            (Sistema de Transferencias y Pagos)
            a la siguiente cuenta CLABE. Esta cuenta CLABE no cambiará, es tuya y se generó ¡sólo para ti! 
          </p>
        </div>
      </div>

      <div v-if="hasStpAccount" class="vx-row mt-5 mb-6">
        <div class="vx-col w-full">
          <div v-if="hasStpAccount" class="vx-col w-full flex">
            <div class="stp-section-container">
              <span class="sm:mx-8 mx-4 bold">{{ stpClabe }}</span>
            </div>
            <vs-button id="copy_accounts_btn" class="ml-2 pt-3 pb-2 px-3" color="primary" @click.stop="copyToClipboard">
              <feather-icon icon="FileTextIcon" svg-classes="h-9 w-9" />
            </vs-button>
          </div>
          <span v-else class="sm:mx-8 mx-4 bold">Contáctanos a través del chat para generar tu cuenta STP y puedas
            realizar este proceso.</span>
        </div>

        <div class="vx-col w-full mt-4">
          <p class="italic">Puedes copiar tu CLABE personalizada al portapapeles al dar clic en el icono junto a la misma.
          </p>
        </div>
      </div>

      <div v-else class="vx-row mb-6">
        <div class="vx-col w-full mt-4">
          <p class="bold italic">En caso de no contar con una cuenta CLABE STP, puedes solicitarla a través del chat.
          </p>
        </div>
      </div>

      <div class="vx-row mt-3 mb-6" v-if="copied">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <vs-alert :active.sync="copied" color="success" closable>CLABE copiada al portapapeles."</vs-alert>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <h4>¿No sabes cuáles son los pasos a seguir? ¡Te los explicamos!</h4>
        </div>
      </div>

      <div class="vx-row mt-3">
        <div class="vx-col w-full">
          <p class="mbot"><span class="list-number">1</span> Una vez que entres al portal de tu banca en línea o tu app
            de banca móvil, selecciona la opción enviar un SPEI.</p>
          <p class="mbot"><span class="list-number">2</span> En el listado de bancos destino, selecciona <span
              class="bold-inline">STP (Sistema de Transferencias y Pagos).</span></p>
          <p class="mbot"><span class="list-number">3</span> Ingresa la cuenta CLABE personalizada: <span
              class="bold-inline">{{ hasStpAccount ? stpClabe : 'En caso de no contar con una cuenta CLABE STP, puedes solicitarla a través del chat.' }}</span>.</p>
          <p class="mbot"><span class="list-number">4</span> En el campo “referencia” o “concepto” podrás escribir lo que
            tú consideres.</p>
          <p class="mbot"><span class="list-number">5</span> En el campo “beneficiario”, escribe tu nombre completo.</p>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <p>
            <br>
            <em>
              Ya que hayas realizado el proceso, tu cuenta estará en proceso de verificación para que podamos trabajar con ella.
              Si tienes dudas, estamos para ayudarte a través del chat de la plataforma.
            </em>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SuccessAlert from "@components/supplier/misc/SuccessAlert";
export default {
  props: ['user_id', 'stpClabe', 'isSupplier'],
  components: { SuccessAlert },
  data() {
    return {
      hasError: false,
      loaded: false,
      copied: null,
    }
  },
  computed: {
    hasStpAccount() {
      if (this.isSupplier) {
        return this.stpClabe != null;
      } else {
        return true;
      }
    }
  },
  methods: {
    reload() {
      window.location.reload();
    },

    async copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.stpClabe;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copied = true;
    }
  }
}
</script>

<style lang="css">
.rounded-space {
  border: 2px solid black;
  padding: 14px;
  font-family: 'gilroybold', 'sans-serif';
  font-size: 22px;
  margin-right: 15px;
  border-radius: 10px;
}

.rounded-icon img {
  height: 70px;
  padding: 8px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.rounded-icon img:hover {
  background-color: #eaeaea;
}

.list-number {
  font-size: 16px;
  background-color: #28DE18;
  color: white;
  font-family: 'gilroybold';
  padding: 2px 11px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 5px;
}

.bold-inline {
  font-family: 'gilroybold';
}

.mbot {
  margin-bottom: 15px;
}

.medium-bold-inline {
  font-family: 'gilroymedium';
}</style>
