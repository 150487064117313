<template>
  <div>
    <vx-card class="mb-6" v-if="!isBackoffice">
      <div class="vx-row">
        <div class="vx-col w-full">
          <h1 class="rg-big-title extrabold rg-title">Actualiza tu <span class="orange">perfil</span>.</h1>
        </div>
        <div class="vx-col w-full">
          <vs-alert v-if="SupplierStatus < 3" icon-pack="feather" icon="icon-alert-triangle" class="mt-4" color="danger">
            <span class="font-regular">Tu perfil está <strong>pendiente</strong> de ser completado. 
            Por favor llena los datos de todas las secciones para que podamos validar tu perfil y puedas acceder a los financiamientos.</span>
          </vs-alert>
          <vs-alert v-if="SupplierStatus == 3" icon-pack="feather" icon="icon-clock" class="mt-4" color="warning">
            <span class="font-regular">Tu perfil se encuentra <strong>en proceso de revisión</strong>.</span>
          </vs-alert>
          <vs-alert v-if="SupplierStatus > 3" icon-pack="feather" icon="icon-check" class="mt-4" color="success">
            <span class="font-regular">Tu perfil se encuentra <strong>verificado</strong>.</span>
          </vs-alert>
          <div v-if="updateRequired">
            <vs-button class="mr-4 mt-4" color="black" @click="requestVerification">Enviar expediente actualizado</vs-button>            
          </div>
        </div>
      </div>
    </vx-card>
    <div v-if="!isVerifiedOrProcess"  class="vx-col w-full mt-4 mb-6 flex" id="account-manage-buttons">
      <vx-card style="background-color: #28DE18 !important;">
        <h4 class="mb-2 white">¿Terminaste la solicitud?</h4>
        <p class="white">No olvides dar clic en <strong>"Solicitar verificación"</strong> al finalizar con la solicitud de revisión de tu perfil.</p>
        <p class="white">Recuerda que en caso de existir cambios, deberás dar clic nuevamente.</p>
        <vs-button class="mr-4 mt-4" color="black" @click="requestVerification">Solicitar verificación</vs-button>            
      </vx-card>
    </div>


    <!-- SECCIONES -->

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab :label="titleForTab(group)" tag-color="000000" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
            <div class="tab-text">
              <vs-alert v-if="group.failed > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                  <span v-for="(miss, i) in group.missing" :key="i">
                    <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                  </span>
                </span>
              </vs-alert>

              <vx-card v-for="section in group.sections" :key="section.data.id" 
              :title="section.data.public_name != 'Los datos bancarios.' ? section.data.public_name : ''" class="mb-5">
              <template v-if="section.data.public_name != 'Los datos bancarios.'">
                <p class="mb-4" v-if="section.data.public_description != null && section.data.id != 9" v-html="section.data.public_description"></p>
                <p class="mb-4" v-if="section.data.id == 9">
                  Por favor indícanos las tarifas que atiendes y las herramientas que utilizas para realizar la cotización y administración de tus proyectos. Selecciona al menos una de las opciones para cada caso. 
                </p>
              </template>
                <div v-if="!isBackoffice && section.data.id == 11 || section.data.id == 12 || section.data.id == 3" class="mb-4">
                  <p class="mb-4">Es necesario que ingreses los documentos especificados a continuación, para que puedas crear la solicitud.
                    <strong>Puedes descargar los formatos aquí:</strong></p>
                  <download-button
                    v-if="isPfae || (isMoral && group.slug == 'contact')"
                    title="Formato de Revisión Crediticia - Persona Física o PFAE"
                    actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                    fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                    :remoteFile="true" />

                  <download-button
                    v-if="isMoral && group.slug == 'general'"
                    title="Formato de Revisión Crediticia -  Persona Moral"
                    actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                    fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                    :remoteFile="true" />
                </div> 

                <div class="vx-row">
                  <template v-for="f in section.data.fields">

                    <!-- Campos automaticos -->
                    <form-field 
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :hasBackofficeAccess="hasBackofficeAccess"
                      :blockedByDocument="f.blockedByDocument"
                      :evaluator="evaluateCondition"
                      :countriesList="collections.countries"
                      :editableByBackoffice="true" />

                    <!-- Campos especiales -->

                    <supplier-branches-control
                      :key="f.id"
                      v-else-if="f.fname == 'supplier_branches_select'"
                      :countriesList="collections.countries"
                      :supplierId="currentSupplierId" />

                    <supplier-cities-control
                      :key="f.id"
                      ref="supplier_cities_component"
                      v-else-if="f.fname == 'supplier_cities_select'"
                      :supplier_id="currentSupplierId"
                      :states="collections.states" />
                      <!-- :disabled="isBackoffice"-->

                    <personal-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :personal_profile_id="businessOwner.id"
                      ref="personal_profile_tins_component"
                      v-else-if="f.fname == 'owner_tins' && should_fill_owner_tins"
                      :disabled="f.blockedByDocument"
                      :countries="collections.countries" />
                      <!--:disabled="f.blockedByDocument || isBackoffice"-->

                     <business-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :business_profile_id="business.id"
                      ref="business_profile_tins_component"
                      v-else-if="f.fname == 'business_tins' && should_fill_business_tins"
                      :disabled="f.blockedByDocument"
                      :countries="collections.countries" />
                      <!--:disabled="f.blockedByDocument || isBackoffice"-->
                      <template v-else-if="f.fname == 'bank_accounts_special' || f.fname == 'bank_accounts_pm_special'">
                        <!-- PROCESAMIENTO CON STP -->
                        <div class="p-4">
                          <stp-account-info 
                            :user_id="currentSupplierUserId" 
                            :stpClabe="stpClabe"
                            :isSupplier="true"
                            />
                        </div>
                        <!-- FIN PROCESAMIENTO CON STP -->
                        <supplier-bank-accounts-control
                          :key="f.id"
                          :f="f"
                          ref="supplier_bank_accounts_control"
                          :isMoral="isMoral"
                          :userId="currentSupplierUserId"
                          :personType="supplierType"
                          :hasBackofficeAccess="hasBackofficeAccess"
                          :userData="base.user"
                          />
                      </template>

                                             
                        <template  v-else-if="f.fname == 'identity_verification_pf' || f.fname == 'identity_verification_pm'">
                          <div :key="f.id" class="ml-4 mr-4">
                            <vs-divider></vs-divider>
                            <h6 class="mb-3">Verificación de identidad</h6>
                            <div class="vx-row m-0">
                              <supplier-identity-verification
                                ref="supplier_identity_verification"
                                :isMoral="isMoral"
                                :userId="currentSupplierUserId"
                                :personType="supplierType"
                                :supplierId="currentSupplierId"
                              />
                            </div>
                          </div>
                        </template>

                    <div class="vx-col lg:w-1/2 md:w-3/4 sm:w-full w-full mb-base" :key="f.id" v-else-if="f.fname == 'supplier_clients_type_selector'" >
                      <input type="hidden" value="someData" name="supplier_clients_type_selector" v-validate="'required'" >
                      <vs-list class="clients_type_list">
                        <vx-tooltip 
                            v-if="f.indication"
                            :title="f.field_name"
                            color="primary"
                            style="float:right;cursor:pointer;margin-top:-8px"
                            :text="f.indication">
                            <vs-chip color="primary">?</vs-chip>
                          </vx-tooltip>
                        <vs-list-header title="¿Cuál es tu principal tipo de cliente?"/>
                        
                        <vs-list-item 
                          v-for="customer in supplierCustomerTypes"
                          :key="customer.type"
                          :title="customer.name">

                          <template slot="avatar">
                            <vs-avatar vs-text="Vue"/>
                          </template>
                          <vs-checkbox color="success" v-model="customer.checked" />
                        </vs-list-item>
                      </vs-list>
                    </div>

                    <div class="vx-col lg:w-1/2 md:w-3/4 sm:w-full w-full mb-base" :key="f.id" v-else-if="f.fname == 'supplier_tools_selector'" >
                      <input type="hidden" value="someData" name="supplier_tools_selector" v-validate="'required'" >
                      <vs-list class="supplier_tools_list">               
                          <vx-tooltip
                            v-if="f.indication"
                            :title="f.field_name"
                            color="primary"
                            style="float:right;cursor:pointer;margin-top:-8px"
                            :text="f.indication">
                            <vs-chip color="primary">?</vs-chip>
                          </vx-tooltip>
                          <vs-list-header title="Herramientas"/>
                        
                        <vs-list-item 
                          v-for="tool in supplierTools"
                          :key="tool.type"
                          :title="tool.name"
                          subtitle="" >

                          <template slot="avatar">
                            <vs-avatar vs-text="Vue"/>
                          </template>

                          <div class="inline-tools">
                            <vs-input 
                              v-model="tool.other_tools"
                              v-validate="'required'"
                              placeholder="Ingrese la descripción"
                              v-if="tool.for_other && tool.checked"
                              name="other_tools"
                              style="margin-right:20px;"
                              :danger="(errors.has('other_tools'))"
                              :danger-text="errors.first('other_tools')"
                              :success="fields['other_tools'] && fields['other_tools'].valid"
                              />
                            <vs-checkbox v-model="tool.checked" color="success"/>
                          </div>
                        </vs-list-item>
                      </vs-list>
                    </div>

                  </template>
                </div>

                <template v-if="section.data.documentFields.length > 0 && needsToShowDocumentsSection(section)">
                  <vs-divider></vs-divider>
                  <h6 class="mb-3">Documentos</h6>
                  <div class="mb-4" v-if="section.data.id == 9">
                    <p class="mb-4">
                      Así mismo, te pedimos que subas una presentación ejecutiva que contenga mínimo los siguientes puntos:
                    </p>
                    <ul class="list-customized">
                      <li>
                        Trayectoria de la empresa.
                      </li>
                      <li>
                        Cuando comenzaron con la instalación de SFV, calentadores, AC, etc.
                      </li>
                      <li>
                        Organigrama donde se aprecie la estructura de la empresa.
                      </li>
                      <li>
                        Imágenes y resumen de la infraestructura con la que cuentan, ej, si tienen 1 o más oficinas, si cuentan con almacén, cuántos vehículos de trabajo y de ventas, etc.;
                      </li>
                      <li>
                        Imágenes y descripción breve de instalaciones realizadas.
                      </li>
                      <li>
                        Ejemplo de cotización o documento de propuesta que se entregue al cliente.
                      </li>
                    </ul>
                  </div>
                  <div class="vx-row">
                    <document-upload
                    v-for="df in section.data.documentFields"
                    :key="df.id"
                    :target_id="df.target_id"
                    :f="df.formField"
                    :doc="df.documentSet"
                    :evaluator="evaluateCondition"
                    :loadedState="df.loadedState"
                    :stickToLoadedState="true"
                    @on-success="onDocumentUploadSuccess"
                    @on-rejected="onDocumentRejected"
                    @on-verified="onDocumentVerified"
                    @on-revoked-verification="onDocumentVerificationRevoked"
                    :editableByBackoffice="true"
                    />
                  </div>
                </template>  
              </vx-card>
              
              <!-- Save Button -->
              <!--div class="vx-row" v-if="!isBackoffice"-->
              <div class="vx-row" v-if="needsToShowSaveButton(group.slug)">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-4 mt-2" color="dark" v-if="!isVerifiedOrProcess" @click="requestVerification">Solicitar verificación</vs-button>
                    <vs-button class="ml-3 mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>                    
                  </div>
                </div>
              </div>

            </div>
          </vs-tab>

          <!-- tab de acreditaciones -->
          <vs-tab :label="accreditationsMissing.length <= 0 ? 'Tecnologías' : `Tecnologías ( ${accreditationsMissing.length} )`" icon-pack="feather" icon="icon-award">
            <div class="tab-text">
               <vs-alert v-if="accreditationsMissing.length > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                  <span v-for="(accMissing, i) in accreditationsMissing" :key="i">
                    <strong>- {{accMissing.n}}: </strong> {{accMissing.e}}<br>
                  </span>
                </span>
              </vs-alert>
              
              <accreditations-control :from_profile="true" :supplier_id="currentSupplierId" />
            </div>
          </vs-tab>

          <!-- tab mis contratos -->
          <vs-tab :label="contractsMissing == 0 ? 'Mis contratos' : `Mis contratos (${contractsMissing})`" icon-pack="feather" icon="icon-archive">
            <div class="tab-text">
               <vs-alert v-if="contractsMissing != 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                  <span v-for="(missing, index) in merchantContractMissing" :key="index">
                    <strong>- {{missing.n}}: </strong> {{missing.e}}<br>
                  </span>
                </span>
              </vs-alert>
              
              <contracts-page 
                :supplier_id="currentSupplierId" 
                :isVerified="identityIsCompleted" 
                :isPM="isMoral"
                :supplier="supplier_data"
                />
            </div>
          </vs-tab>

          <vs-tab label="Notificaciones" icon-pack="feather" icon="icon-mail">
            <div class="tab-text">
              <NotificationsPage></NotificationsPage>
            </div>
          </vs-tab>

          <!-- perfil fiscal del instalador -->
          <vs-tab label="Perfil fiscal" icon-pack="feather" icon="icon-mail">
            <div class="tab-text">
              <SupplierTaxProfile></SupplierTaxProfile>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import docsHelper from '@components/mixins/docsHelper';
import FormField from '@components/forms/FormField.vue';
import SupplierCitiesControl from '@supplier/SupplierCitiesControl.vue';
import SupplierTestimoniesControl from '@supplier/SupplierTestimoniesControl.vue';
import PersonalProfileTinsControl from '@supplier/PersonalProfileTinsControl.vue';
import BusinessProfileTinsControl from '@supplier/BusinessProfileTinsControl.vue';
import AccreditationsControl from '@supplier/AccreditationsControl.vue';
import SupplierBankAccountsControl from '@supplier/SupplierBankAccountsControl.vue';
import SupplierIdentityVerification from '@supplier/SupplierIdentityVerification.vue';
import NotificationsPage from "@views/supplier/profile/NotificationsPage";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import ContractsPage from "@views/supplier/contracts/ContractsPage";
import { Validator } from 'vee-validate'
import SupplierBranchesControl from '../../../components/supplier/profile/SupplierBranchesControl.vue';
import SupplierTaxProfile from "@views/supplier/profile/SupplierTaxProfile";
import StpAccountInfo from "@components/stp/StpAccountInfo";
import stpHelper from "@mixins/stpHelper";

const pfaeTabsDefs = [
  { slug: "general", title: "Datos generales",  icon: "icon-briefcase", sections:[1,2] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[4,5] },  
  { slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[6] },
  { slug: "contact", title: "Contacto", icon: "icon-phone", sections:[3,10,17,18,139] },
  { slug: "knowledge", title: "Conocimiento", icon: "icon-bookmark", sections:[22,9] },
];

const pmTabsDefs = [
  { slug: "general", title: "Datos Generales", icon: "icon-briefcase", sections:[1,11,2] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[23,24] },  
  { slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[13] },
  { slug: "contact", title: "Contacto", icon: "icon-phone", sections:[12,138,10,17,18] },
  { slug: "knowledge", title: "Conocimiento", icon: "icon-bookmark", sections:[22,9] },
];

const sectionsToContainers = [
  { id: 1, containers: [{db:'suppliers', path:'base'}] },
  { id: 2, containers: [{db:'suppliers', path: 'base'}] },
  { id: 3, containers: [
    {db:'personal_profiles', path:'user.personal'},
    {db:'suppliers', path:'base'},
    {db:'personal_credit_profiles', path:'user.personal.credit_profile'},
    {db:'phones', path:'user.personal.phone', arrayDefs:{ onNull: 'newPhone'}},
  ]},
  { id: 4, containers: [{db:'addresses', path:'user.personal.business_address'}] },
  { id: 139, containers: [{db:'addresses', path:'user.personal.address'}] },
  { id: 5, containers: [{db:'addresses', path:'user.personal.fiscal_address'}] },
  { id: 6, containers: [{db:'bank_accounts', path:'user.personal.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}}] },
  { id: 9, containers: [{db:'suppliers', path:'base'}]},
  { id: 10, containers: [
    {db:'supplier_contacts', path:'contacts', arrayDefs: {eval: 'filterGeneralContact', onNull: 'newGeneralContact'}},
    {db:'phones', path:'contacts', arrayDefs:{eval: 'filterGeneralContactForNumber',onNull: 'newPhone'}},
  ] },
  { id: 11, containers: [
    {db:'business_profiles', path:'user.business'},
    {db:'business_profile_tins', path:'user.business.tins', arrayDefs:{}},
    {db:'phones', path:'user.business.phone', arrayDefs:{ onNull: 'newPhone'}},
    {db:'business_credit_profiles', path:'user.business.credit_profile'},
  ]},
  { id: 12, containers: [
    {db:'personal_profiles', path:'user.business.personal'},
    {db:'phones', path:'user.business.personal.phone', arrayDefs:{ onNull: 'newPhone'}},
    {db:'addresses', path:'user.business.personal.address'},
    {db:'business_profiles', path:'user.business'},
    {db:'business_profile_tins', path:'user.business.tins', arrayDefs:{}},
    {db:'personal_credit_profiles', path:'user.business.personal.credit_profile'},
  ]},
  { id: 138, containers: [{db:'addresses', path:'user.business.personal.address'}] },
  { id: 13, containers: [
    {db:'bank_accounts', path:'user.business.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}},
    {db:'banks', path: 'bank_accounts_bank' }
  ]},
  { id: 17, containers: [
    {db:'supplier_contacts', path:'contacts', arrayDefs: {eval: 'filterCommercialContact', onNull: 'newCommercialContact'}},
    {db:'phones', path:'contacts', arrayDefs:{eval: 'filterCommercialContactForNumber', onNull: 'newPhone'}},
  ] },
  { id: 18, containers: [
    {db:'supplier_contacts', path:'contacts', arrayDefs: {eval: 'filterOperationsContact', onNull: 'newOperationsContact'}},
    {db:'phones', path:'contacts', arrayDefs:{eval: 'filterOperationsContactForNumber', onNull: 'newPhone'}},
  ] },
  { id: 22, containers: [] },
  { id: 23, containers: [{db:'addresses', path:'user.business.address'}] },
  { id: 24, containers: [{db:'addresses', path:'user.business.fiscal_address'}] }
];

const requiredObjects = [
  'user.personal.address',
  'user.personal.fiscalAddress',
  'user.personal.businessAddress',
  'user.personal.bankAccounts',
  'user.personal.tins',
  'user.personal.creditProfile',
  'user.personal.phone',
  'user.business.personal',
  'user.business.address',
  'user.business.tins',
  'user.business.phone',
  'user.business.personal.address',
  'user.business.personal.phone',
  'user.business.fiscalAddress',
  'user.business.bankAccounts.bank',
  'user.business.personal.creditProfile',
  'contacts',
  'tools',
  'customerTypes'
];

const arrayPaths = ['user.business.bank_accounts', 'user.personal.bank_accounts', 'user.business.tins', 'contacts', 'contacts.phone'];
const SUPPLIER_COMMERCIAL_COMMISSION_CONTRACT_TYPE = 5;
const SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE = 14;

const addressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
};
const fiscalAddressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
  address_equals_fiscal_address: null
}
const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";

export default {
  mixins: [formHelper, inputHelper, dateHelper, docsHelper, userIdentityHelper, stpHelper],
  props: ['supplier_id', 'user_id', 'supplier_status', 'tab_index'],
  data(){
    return {
      moralTypeValue: 0,
      pfaeTypeValue: 1,
      flow_id_pfae: 1,
      flow_id_pm: 2,
      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      flow: {},
      allSections: [],
      sectionGroups: [],
      documentsStates: [],

      tabs: [],

      supplierType: null,
      isMoral: false,
      isPfae: false,
      activeTab: 0,

      missing: {
        enterprise: '',
        acredit: '',
        contact: '',
        residence: '',
        bank: ''
      },

      collections: {
        cities: [],
        states: [],
        countries: [],
        foreign_countries: [],
        family_groups: [],
        tools: [],
        customer_types: [],
        banks: [],
        fiscal_states: [],
        fiscal_cities: [],
        fiscal_neighborhoods: [],
        neighborhoods: [],

        cities_personal_address: [],
        states_personal_address: [],
        neighborhoods_personal_address: [],
        states_personal_fiscal_address: [],
        cities_personal_fiscal_address: [],
        neighborhoods_personal_fiscal_address: [],
        states_business_address: [],
        cities_business_address: [],
        neighborhoods_business_address: [],
        states_business_fiscal_address: [],
        cities_business_fiscal_address: [],
        neighborhoods_business_fiscal_address: [],
        states_business_owner_personal_address: [],
        cities_business_owner_personal_address: [],
        neighborhoods_business_owner_personal_address: [],
        states_personal_business_owner_address: [],
        cities_personal_business_owner_address: [],
        neighborhoods_personal_business_owner_address: []
      },

      supplierTools: [],
      supplierCustomerTypes: [],
      supplierTins: [],

      // objeto principal
      base: {},

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
      totalFields: 0,
      totalValidFields: 0,
      totalInvalidFields: 0,

      validatedDocuments: [],
      allDocumentFields: [],

      skipMissingWarnings: false,
      accreditationsMissing: [],
      merchantContractMissing: [],
      contractsMissing:0,
      
      currentSupplierId: null,
      currentSupplierUserId: null,
      currentSupplierStatus: null,

      isBackoffice: false,

      bankAccountsValidation: null,
      status: null,
      identityIsCompleted: false,
      SUPPLIER_COMMERCIAL_COMMISSION_CONTRACT_TYPE:SUPPLIER_COMMERCIAL_COMMISSION_CONTRACT_TYPE,
      SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE:SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE,
	    zipCode: null,
      stpClabe: null,
    }
  },
  components: {
    FormField,
    SupplierCitiesControl,
    SupplierTestimoniesControl,
    PersonalProfileTinsControl,
    BusinessProfileTinsControl,
    AccreditationsControl,
    SupplierBankAccountsControl,
    SupplierIdentityVerification,
    ContractsPage,
    SupplierBranchesControl,
    NotificationsPage,
    SupplierTaxProfile,
    StpAccountInfo
  },
  computed: {
    supplier_data(){
      return [{
        type: 'supplier'
        , name: this.isMoral ? this.getPersonalFullName(this.businessOwner) : this.getPersonalFullName(this.personal)
        , email: this.base.user.email
        , telephone: this.isMoral ? this.businessOwner.phone : this.personal.phone
      }];
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    isVerifiedOrProcess(){
      return this.currentSupplierStatus >= 3;
    },
    personal(){
      return this.base.user.personal;
    },
    business(){
      return this.base.user.business;
    },
    businessOwner(){
      return this.base.user.business.personal;
    },
    personalBankAccount(){
      return this.base.user.personal.bank_accounts[0];
    },
    businessBankAccount(){
      return this.base.user.business.bank_accounts[0];
    },
    supplier_pf_bank_account_exists(){
      return this.personalBankAccount && this.personalBankAccount.id != null;
    },
    supplier_pf_bank_account_id(){
      return this.personalBankAccount ? this.personalBankAccount.id : null;
    },
    supplier_pm_bank_account_exists(){
      return this.businessBankAccount && this.businessBankAccount.id != null;
    },
    supplier_pm_bank_account_id(){
      return this.businessBankAccount ? this.businessBankAccount.id : null      
    },

    /* show_only_if getters */
    should_fill_personal_fiscal_address(){
      return !this.base.user.personal.fiscal_address.address_equals_fiscal_address;
    },
    should_fill_business_fiscal_address(){
      return !this.business.fiscal_address.address_equals_fiscal_address;
    },
    should_fill_business_mexican_address(){
      return this.business.address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_address(){
      return this.business.address.country_id != this.mexicoCountryId;
    },
    should_fill_business_mexican_fiscal_address(){
      return this.should_fill_business_fiscal_address 
      && this.business.fiscal_address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_fiscal_address(){
      return this.should_fill_business_fiscal_address 
      && this.business.fiscal_address.country_id != this.mexicoCountryId;
    },
    should_fill_pm_rfc(){
      return this.business.nationality == this.mexicoCountryId;
    },
    should_fill_business_tins(){
      return this.business.nationality && this.business.nationality != this.mexicoCountryId;
    },
    should_fill_personal_rfc(){
      // return this.personal.citizenship == this.mexicoCountryId;
      return this.supplierType == 1;
    },
    should_fill_owner_rfc_curp_fiscal_rec(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_tins(){
      return this.businessOwner.citizenship && this.businessOwner.citizenship != this.mexicoCountryId;
    },
    should_fill_personal_curp(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_curp(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },    
    power_proof_is_required(){
      return this.business.power_proof_required;
    },
    should_fill_pm_foreign_bank_info(){
      return this.business.nationality != this.mexicoCountryId && !this.businessBankAccount.pm_bank_is_in_mexico;
    },
    should_fill_pm_clabe(){
      return (this.business.nationality == this.mexicoCountryId || this.businessBankAccount.pm_bank_is_in_mexico == true) && this.is_allowed;
    },
    should_fill_pm_swift_bic(){
      return this.should_fill_pm_foreign_bank_info && this.is_allowed;
    },
    should_fill_pm_account_number(){
      return (this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "") && this.is_allowed);
    },
    should_fill_pm_routing_number(){
      return (this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "") && this.is_allowed);
    },
    should_fill_pm_iban(){
      return this.should_fill_pm_foreign_bank_info && this.is_allowed;
    },
    should_fill_pm_bank_in_mexico(){
      return this.business.nationality != this.mexicoCountryId;
    },

    should_ask_for_pm_bank_confirmation(){
      return ((this.business.nationality != this.mexicoCountryId) && this.is_allowed);
    },

    /* disabled_if getters */
    should_fill_personal_bdate_gender(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_bdate_gender(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    personal_clabe_from_bank(){
      return this.clabeBankName;
    },
    is_allowed(){
      return false;
    },
    updateRequired(){
      return this.SupplierStatus >= 4 
      && this.SupplierUpdateProfileInfo.update_profile_requirement === 1 
      && this.SupplierUpdateProfileInfo.update_profile_completed_for_review_date === null
    }
  },
  async beforeMount() {
    this.loading = true;
    this.isMounted = false;
    this.isBackoffice = process.env.VUE_APP_IS_BACKOFFICE == "true";
    this.setSupplier();
    await this.getCollections();
    await this.getSupplierData();
    await this.getSupplierStatusInfo();
    this.setPersonTypeInfo();
    await this.setCustomIntegrations();
    await this.getFormInfo();
    this.setSectionGroups();
    this.setBlockedDocuments();
    this.setTabFromParams();
    await this.checkForStpInfo();
    if(this.currentSupplierStatus < 3 || this.updateRequired){
      await this.runSilentValidation(true);
    } else {
      await this.getCCMStatus()
    }
    this.isMounted = true;    
    this.loading = false;
  },
  async mounted() {
    this.activeTab = this.tab_index ?? 0;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    async checkForStpInfo(){
      // solicitar informacion de stp solo si corresponde
      // panel completo y persona fisica mexicana
      this.stpClabe = await this.getSupplierAccountStpClabe(this.currentSupplierId);
    },
    setSupplier(){
      if(this.isBackoffice){
        this.currentSupplierId = this.$props.supplier_id;
        this.currentSupplierStatus = this.$props.supplier_status;
        this.currentSupplierUserId = this.$props.user_id;
      }
      else {
        this.currentSupplierId = this.SupplierId;
        this.currentSupplierStatus = this.SupplierStatus;
        this.currentSupplierUserId = this.UserId;
      }
    },
    setTabFromParams(){
      let techTab = this.tabs.length;

      let tab = this.$route.query.tab;
      if(tab && tab == "tecnologias"){
        this.activeTab = techTab;
      }
    },
    titleForTab(group){
      if(group.failed == 0){
        return group.title;
      }
      else {
        return `${group.title} ( ${group.failed} )`;
      }
    },
    resetCounters(){
      this.totalFields = 0;
      this.totalValidFields = 0;
      this.totalInvalidFields = 0;
      this.accreditationsMissing = [];
      this.merchantContractMissing = [];
      this.contractsMissing = 0;
    },
    async runSilentValidation(isAutomatic = false){
      try {
        this.resetCounters();
        let validator = new Validator();
        let container;
        await this.asyncForEach(this.sectionGroups, async (group) => {
          group.missing = [];
          group.failed = 0;
          await this.asyncForEach(group.sections, async (section) => {
            await this.asyncForEach(section.data.fields, async (f) => {
              container = this.getContainerForField(section, f);
              if(this.notDocumentNorPrivate(f)){
                if(this.isAutoFormField(f)) {
                  let res = await this.evaluateFieldCompletition(f, container, this.evaluateCondition, validator);
                  //  console.log(section.data.id + ", " + f.fname + "=> " + res);
                  if (res != null) {
                    if (res == false) {
                      section.failed += 1;
                      group.failed += 1;
                      group.missing.push({n: f.field_name, e: "Campo requerido"});
                      this.totalInvalidFields += 1;
                    }
                  }
  
                  if(f.db_field === "rfc" && f.special_validation){
                    let res = await this[f.special_validation.method]();
                    let errors = res.length;
                    if(errors > 0){
                      section.failed += errors;
                      group.failed += errors;
                      this.totalInvalidFields += errors;
                      res.forEach(item => {
                        group.missing.push({n: item.n, e: item.e } );
                      })
                    }
                  }
                }
                else if(f.special_definition == 1 && f.hard_validation){
                  let res = await this[f.hard_validation.method]();
                  if(!res){
                    section.failed += 1;
                    group.failed += 1;
                    if(f.hard_validation.custom_error !== null && f.hard_validation.custom_error !== undefined){
                      const customError = await this[f.hard_validation.custom_error]();
                      group.missing.push({n: f.field_name, e:customError } );
                    }
                    else if(f.hard_validation.error !== null && f.hard_validation.error !== undefined) {
                      group.missing.push({n: f.field_name, e:f.hard_validation.error } );
                    }
                    this.totalInvalidFields += 1;
                  }
                }
                this.totalFields += 1;
              }
            });
  
            section.data.documentFields.forEach(df => {
              if(!df.loadedState && df.documentSet.is_required == 1 && df.formField.bo_private != 1){
                if(df.formField.show_conditioned){
                  let inc = this.evaluateCondition(df.formField.condition);
                  if(inc != true){
                    return;
                  }
                }              
                section.failed += 1;
                group.failed += 1;
                this.totalInvalidFields += 1;
                group.missing.push({n: df.documentSet.name, e: "Documento requerido"} );
              } 
              else if(df.loadedState && df.loadedState.is_verified == rejectedEnumValue){
                section.failed += 1;
                group.failed += 1;
                this.totalInvalidFields += 1;
                group.missing.push({n: df.documentSet.name, e: "Documento en estado rechazado"} );
              }
            })
          })
        });
      } catch (error) {
        console.log(error);
      }
      
      try {
        let res = await axios.get(`/api/v1/supplier/${this.currentSupplierId}?with[]=supplierFamilyGroups.familyGroup`);
        let sp = res.data.supplier_family_groups.filter(acc => acc.accreditation_status > 0);
        if(sp.length < 1){
          this.totalInvalidFields += 1;
          this.accreditationsMissing.push({n: "Tecnologías", e: "Debes solicitar al menos una tecnología"});
        } else if (this.updateRequired){
          res.data.supplier_family_groups.forEach( async (element) => {
            this.checkAccreditationWhenUpdateIsRequired(element);
          });
        }
      }
      catch (e) {
        this.warn(e);
      }

      try {
        let res = await axios.get(`/api/supplier/contracts/${this.currentSupplierId}/verify/${this.SUPPLIER_COMMERCIAL_COMMISSION_CONTRACT_TYPE}`); // VALIDA SI EL CONTRATO DE COMISIÓN MERCANTIL YA ESTÁ FIRMADO CON FAD
        let merchant = res.data.length;
        if(merchant < 1){
          this.totalInvalidFields += 1;
          this.contractsMissing++;
          this.merchantContractMissing.push({n: "Contrato Comisión Mercantil", e: "Debes haber firmado el Contrato de Comisión Mercantil con FAD."});
        }  
      }
      catch (e) {
        this.warn(e);

      }try {
        let res = await axios.get(`/api/supplier/contracts/${this.currentSupplierId}/verify/${this.SUPPLIER_CONSTANCY_KNWOLEDGE_TYPE}`); // VALIDA SI LA CONSTANCIA CONOCIMIENTO YA FUE ACEPTADA
        let knowledge = res.data.length;
        if(knowledge < 1){
          this.totalInvalidFields += 1;
          this.contractsMissing++;
          this.merchantContractMissing.push({n: "Constancia de Conocimiento", e: "Debes haber aceptado la Constancia de Conocimiento."});
        }  
      }
      catch (e) {
        this.warn(e);
      }

      if(isAutomatic && this.totalInvalidFields === 0){
        this.suggestVerification();
      }

    },

    async checkAccreditationWhenUpdateIsRequired(accreditation){
      try {
        let family_group_id = accreditation.family_group_id;
        let techName = accreditation.family_group.name;

        let params = "?with[]=supplierFgTestimonies&with[]=supplierFgTestimonies.testimonyVerification&&filter[familyGroups.id]=" + family_group_id;
        let response = await axios.get(`/api/v1/supplier/${this.currentSupplierId}${params}`);
        let tests = response.data.supplier_fg_testimonies.filter(f => f.family_group_id == family_group_id);
        if(tests.length < 3){
          this.totalInvalidFields += 1;
          this.accreditationsMissing.push({n: `${techName} - TESTIMONIOS`, e: "Debes agregar al menos 3 testimonios"} );
        }

        tests.forEach(t => {
          if(t.testimony_verification != null){
            if(t.testimony_verification.is_verified == rejectedEnumValue) {
              this.totalInvalidFields += 1;
              this.accreditationsMissing.push({n: `${techName} - TESTIMONIOS`, e: `Ha sido rechazado el testimonio ${t.complete_name}, deberás eliminarlo y agregar uno nuevo.`} );
            }
          }
        });

        let res = await axios.get(`/api/v1/fg_survey/family_group/${family_group_id}/supplier/${this.currentSupplierId}/getSupplierInterviewResponses`);
        let supplierInterviewResponses = res.data;
        if(supplierInterviewResponses.length < 1){
          this.totalInvalidFields += 1;
          this.accreditationsMissing.push({n: `${techName} - CUESTIONARIO`, e: "Debes responder el cuestionario"} );
        }
      }
      catch (error) {
        console.error(error);
      }
    },

    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getSupplierData();
      await this.setCustomIntegrations();
      this.reloadSectionGroups();
      // await this.runAddresses();
      this.isRefreshing = false;
    },    
    async saveTabChanges(gslug){
      this.loading = true;
      this.skipMissingWarnings = false;
      let result = await this.runValidations(gslug);
      if(!result){
        if(!this.skipMissingWarnings){
          this.missingFieldsNotif();
        }
      }
      else {

        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        if(this.objectIsEmpty(this.basePayload) && gslug != "knowledge"){
          //this.notFieldsToUpdate();
          await this.runSilentValidation(true);
          this.saveSuccessNotif();
          this.loading = false;
          return;
        }

        this.basePayload.id = this.base.id;
        if(gslug == 'knowledge'){
          // recoleccion manual de valores
          this.collectKnowledgeData();
        }
        
        try {
          // ejecutar guardado
          this.injectAccountMetadataToPayload(this.basePayload);
          await axios.put(`/api/v1/supplier/${this.currentSupplierId}/deepUpdate`, this.basePayload);
          // solicitar la informacion actualizada del modelo
          await this.reloadFormInfo();

          // actualizar la informacion general del usuario en caso de ser necesario
            if(gslug == "general" && !this.isBackoffice){
              await this.onUserInfoUpdated();
            }
          await this.runSilentValidation(true);
          this.saveSuccessNotif();          
        }
        catch (error) {
          this.warn(error);
          this.failedOperationNotif(null, null);
        }
      }
      this.loading = false;        
    },
    async runValidations(gslug){
       // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }
      
      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }
    
      // inspeccionar los campos para ejecutar validaciones especiales en campos tipo FormField
   //   let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
   //   if(!spValidation){
    //    return false;
    //  }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;

      await this.asyncForEach(group.sections, async (s) => {
        await this.asyncForEach(s.data.fields.filter(f => f.special_definition == 1), async (f) => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = await this[f.special_validation.method]();

            if(!res){              
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        this.skipMissingWarnings = true;
        return false;
      }

      let extra_valid = await this.extraValidations(gslug);
      if(!extra_valid){
        this.skipMissingWarnings = true;
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    async extraValidations(group_slug){
      let ok = true;
      if(group_slug == "contact"){
        // validar que los 3 juegos de datos de contacto sean distintos
        let general = this.base.contacts.find(f => f.type == 1);
        let comm = this.base.contacts.find(f => f.type == 2);
        let ops = this.base.contacts.find(f => f.type == 3);

        let n1 = general.name.toLowerCase();
        let n2 = ops.name.toLowerCase();
        let n3 = comm.name.toLowerCase();

        let e1 = general.email.toLowerCase();
        let e2 = ops.email.toLowerCase();
        let e3 = comm.email.toLowerCase();

        let p1 = general.phone;
        let p2 = comm.phone;
        let p3 = ops.phone;
        
        if(n1 == n2 || n1 == n3 || n3 == n2){
          ok = false;
        }
        if(e1 == e3 || e1 == e2 || e3 == e2){
          ok = false;
        }
        if(p1 == p2 || p1 == p3 || p2 == p3){
          ok = false;
        }
        
        if(!ok){
          this.errorNotif("Error en datos de contacto", 
            "Es necesario que los datos de contacto del Director General, Director Comercial y Director de Operaciones sean distintos entre sí.", 10000);
        }
      }
      return ok;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            if(arrayPaths.includes(cc.path)){
              // se integran todos los objetos de la coleccion
              obj = this.nestedFieldValue(this.base, cc.path);
            }
            else {
              obj = {id: cc.container.id};
              fields.forEach(f => {
                obj[f.fname] = cc.container[f.fname];
              });
            }
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }          
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null && sp.method in this){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              if(f.db_name === "rfc" && res.length === 0) {
                continue;
              }
              this.skipMissingWarnings = true;
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },

    collectKnowledgeData(){
       // Herramientas del proveedor
        let t = [];
        this.supplierTools.forEach(f => {
          let item = {};
          item.type = f.type;
          item.other_tools = f.other_tools;
          item.checked = f.checked;
          t.push(item);
        });
        this.basePayload.tools = t;

         // Customer Types
        let c = [];
        this.supplierCustomerTypes.forEach(f => {
          let item = {};
          item.type = f.type;
          item.checked = f.checked;
          c.push(item);
        });
        this.basePayload.customer_types = c;
    },

    async suggestVerification(){
      let message = "Una vez que concluyas con el perfil, da clic en el botón “Solicitar verificación” para concluir con el proceso.";
      let acceptText = "Enviar solicitud ahora";
      if(this.updateRequired){
        message = `Una vez termines de actualizar el expediente, da clic en el botón "Enviar expediente actualizado" para concluir con el proceso.`;
        acceptText = "Enviar expediente actualizado";
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: '¡No olvides enviar la solicitud completa!',
        text: message,
        acceptText: acceptText,
        cancelText: 'Cancelar',
        accept: this.doRequestVerification
      });
    },

    async requestVerification(){
      let ok  = await this.checkForValidVerification();
      if(!ok){
        return;
      }
      let message = `¿Estás seguro que deseas enviar tu perfil a revisión? Una vez enviada no podrás modificar los datos.`;
      let acceptText = `Enviar solicitud`;
      let title = "Solicitar verificación de perfil";
      if(this.updateRequired){
        message = `¿Estás seguro que deseas enviar tu expediente actualizado a revisión? Una vez enviada no podrás modificar los datos.`;
        acceptText = "Enviar expediente actualizado";
        title = "Solicitar verificación de expediente";
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: title,
        text: message,
        acceptText: acceptText,
        cancelText: 'Cancelar',
        accept: this.doRequestVerification
      });
    },
    async doRequestVerification(){
      this.loading = true;
      try {
        let res = null;
        if(this.updateRequired){
          res = await axios.post(`/api/v1/supplier/profile/update/verification`);
        } else {
          let payload = { supplier_id: this.currentSupplierId };
          res = await axios.post(`/api/v1/supplier/requestProfileVerification`, payload);
        }
        if(res.data == "success"){
          this.saveSuccessNotif("Perfil enviado a revisión", "Tu perfil ha sido enviado a revisión exitosamente. En breve tendrás respuesta.", 8000);
          await this.onUserInfoUpdated();
        }
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.loading = false;
    },
    async checkForValidVerification(){
      this.showLoading(true)
      // TODO verificar datos desde back
      await this.runSilentValidation();
        this.showLoading(false)
      if(this.totalInvalidFields > 0){
        this.errorNotif("Campos pendientes", 
        "Existen campos pendientes para realizar la verificación. Por favor revisa las pestañas que indiquen un número de pendientes para ver el detalle.", 10000); 
        return false;
      }
      return true;
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'user' && cls_2 == 'supplier'){
        return this.currentSupplierUserId;        
      }
       this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    setPersonTypeInfo(){
      this.supplierType = this.base.user.person_type;
      this.isMoral = this.supplierType == this.moralTypeValue;
      this.isPfae = this.supplierType == this.pfaeTypeValue;
      this.tabs = this.isMoral ? pmTabsDefs : pfaeTabsDefs;
    },
    async setCustomIntegrations(){
       // Herramientas del proveedor
        let t = [];
        this.collections.tools.forEach(f => {
          let ss = this.base.tools.find(x => x.type == f.type);
          let item = {};
          item.name = f.name;
          item.type = f.type;
          item.checked = ss != null;
          item.for_other = f.for_other;
          item.other_tools = ss != null ? ss.other_tools : null;
          t.push(item);
        });
        this.supplierTools = t;

        // Tipos de cliente del proveedor
        let c = [];
        this.collections.customer_types.forEach(f => {
          let item = {};
          item.type = f.type;
          item.name = f.name;
          item.checked = this.base.customer_types.find(x => x.type == f.type) != null;
          c.push(item);
        });
        this.supplierCustomerTypes = c;

        // direcciones personal profile
        let padd_sid = this.nestedFieldValue(this.base, 'user.personal.address.state_id');
        let pfadd_sid = this.nestedFieldValue(this.base, 'user.personal.fiscal_address.state_id');
        if(padd_sid != null && padd_sid != ""){ await this.reload_personal_cities() }
        if(pfadd_sid != null && pfadd_sid != ""){ await this.reload_personal_cities_fiscal()}

        let padd = this.nestedFieldValue(this.base, 'user.personal.address.id');
        let pfadd = this.nestedFieldValue(this.base, 'user.personal.fiscal_address.id');
        if(padd && pfadd && padd == pfadd){
          this.personal.fiscal_address = fiscalAddressMinDefault;
          this.personal.fiscal_address.address_equals_fiscal_address = true;
        }

        // direcciones business profile
        let badd_sid = this.nestedFieldValue(this.base, 'user.business.address.state_id');
        let bfadd_sid = this.nestedFieldValue(this.base, 'user.business.fiscal_address.state_id');        
        if(badd_sid != null && badd_sid != ""){ await this.reload_business_cities() }
        if(bfadd_sid != null && bfadd_sid != ""){ await this.reload_business_cities_fiscal()}

        let badd = this.nestedFieldValue(this.base, 'user.business.address.id');
        let bfadd = this.nestedFieldValue(this.base, 'user.business.fiscal_address.id');
        if(badd && bfadd && badd == bfadd){
          this.business.fiscal_address = fiscalAddressMinDefault;
          this.business.fiscal_address.address_equals_fiscal_address = true;
        }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug, missing: [] }; //, vModel: this.getModelForGroup(t.slug) };
        let sections = [];
        
        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id), failed: 0 };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        group.failed = 0;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    /*getModelForGroup(slug){
      return this[slug];
    },*/
    getContainerForField(section, f){      
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        if(c.path == 'bank_accounts_bank'){
          let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
          let nbankAcc = accs[0];
          return nbankAcc.bank;
        }
        if(container == null && c.arrayDefs) {
          let ac = null;
          if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval]();
            return ac;
          }
          if(!ac){
            if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
            else { ac = {} }
            container = ac;
          }
        }
        return container;
      }
    },
    async getSupplierStatusInfo(){
      // TODO
    },
    async getCCMStatus() {
      this.status = await this.getUserIdentityStatus(this.currentSupplierUserId);
      this.identityIsCompleted = this.verificationIsCompleted(this.status);
    },
    async getSupplierData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/supplier/${this.currentSupplierId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },    
    async getFormInfo(){
      try{
        let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pfae;
        let response = await axios.get("/api/v1/forms/getFlowSectionFields/" + id);
        let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;

        this.flow = response.data.flow;
        this.allSections = response.data.sections;
        this.allDocumentFields = [];

        await this.asyncForEach(this.allSections, async (s) => {
          if(this.tabs.filter(tt => tt.sections.includes(s.id)).length < 1){            
            return;
          }

          s.documentFields = [];
          await this.asyncForEach(s.fields, async (f) => {
            this.formFieldSetter(f, this);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                let dset = ss.documents.find(d => d.id == f.doc_id);
                if(dset){
                  ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                  ff.documentSet = dset;
                }
              }
              s.documentFields.push(ff);
              this.allDocumentFields.push(ff);
            }
          });          

       //   let states = await this.getAllDocumentsStates(allDocumentFields, this.evaluateCondition);

          // solicitar los estatus de cada documento
          /*
          let file = await this.getDocumentState(ff.target_id, f.doc_id, dset.clasification, 
          this.evaluateCondition, f.extras);
          ff.loadedState = file;

          // agregar a la lista de documentos verificados
          if(file && file.is_verified == verifiedEnumValue){
            this.validatedDocuments.push(f.doc_id);
          }*/


        });
        
        let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
        if(res){
          this.allDocumentFields.forEach(f => {
            if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
              this.validatedDocuments.push(f.documentSet.id);
            }
          })
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    onDocumentUploadSuccess(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    onDocumentRejected(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    onDocumentVerified(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
      this.validatedDocuments.push(d.documentSet.id);
      this.allSections.forEach(section => {
        section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = true);
      })
    },
    onDocumentVerificationRevoked(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
      this.validatedDocuments = this.validatedDocuments.filter(f => f != d.documentSet.id);
      this.allSections.forEach(section => {
        section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = false);
      })
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList', 'familyGroups', 'availableSupplierTools',
            'supplierCustomerTypesCatalog', 'banksForMexico'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        let colls = res.data;
        this.collections.countries = colls.countriesList;
        this.collections.foreign_countries = colls.foreignCountriesList;
         this.collections.states = colls.statesList;
        this.collections.family_groups = colls.familyGroups;
        this.collections.tools = colls.availableSupplierTools;
        this.collections.customer_types = colls.supplierCustomerTypesCatalog;
        this.collections.banks = colls.banksForMexico;

      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    setBlockedDocuments(){
      this.validatedDocuments.forEach(doc => {
        this.allSections.forEach(section => {
          section.fields.filter(f => f.block_by_document_id == doc).forEach(fd => fd.blockedByDocument = true);
        })
      })
    },

    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = this.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_personal_cities_fiscal(){
      try {
        let sid = this.personal.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities(){
      try {
        let sid = this.business.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities_fiscal(){
      try {
        let sid = this.business.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    personal_curp_conditions_changed(){
      let c = this.personal.curp;
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);
        
        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.personal.birth_date = null;
        }
        else{
          this.personal.birth_date = date;
        }
      }
      else{
        this.personal.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.personal.gender = 1;
        }
        else if(g == "M"){
          this.personal.gender = 2;
        }
        else{
          this.personal.gender = null;
        }
      }
      else{
        this.personal.gender = null;
      }
    },
    owner_curp_conditions_changed(){
      let c = this.businessOwner.curp;
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);
        
        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.businessOwner.birth_date = null;
        }
        else{
          this.businessOwner.birth_date = date;
        }
      }
      else{
        this.businessOwner.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.businessOwner.gender = 1;
        }
        else if(g == "M"){
          this.businessOwner.gender = 2;
        }
        else{
          this.businessOwner.gender = null;
        }
      }
      else{
        this.businessOwner.gender = null;
      }
    },

    /* funciones de secciones */
    filterGeneralContact(collection){
      return collection.find(f => f.type == 1);
    },
    filterCommercialContact(collection){
      return collection.find(f => f.type == 2);
    },
    filterOperationsContact(collection){
      return collection.find(f => f.type == 3);
    },   
     /* funciones de secciones */
    filterGeneralContactForNumber(){
      return this.base.contacts.find(f => f.type == 1);
    },
    filterCommercialContactForNumber(){
      return this.base.contacts.find(f => f.type == 2);
    },
    filterOperationsContactForNumber(){
      return this.base.contacts.find(f => f.type == 3);
    },
    newGeneralContact(){
      return {type: 1, phone: this.newPhone};
    },
    newCommercialContact(){
      return {type: 2, phone: this.newPhone};
    },
    newOperationsContact(){
      return {type: 3, phone: this.newPhone};
    },
    newBankAccount(){
      return {id: null, bank: {}};
    },
    newPhone(){
      return {country_calling_code: null, phone: null};
    },

    /* validaciones especiales */
    validate_user_birthdate(){
      let years = this.calculateAgeFromDate(this.personal.birth_date);
      return years >= 18;
    },

    validate_owner_birthdate(){
      let years = this.calculateAgeFromDate(this.businessOwner.birth_date);
      return years >= 18;
    },
    validate_supplier_tools(){
      return this.supplierTools.filter(f => f.checked == true).length > 0;
    },
    validate_supplier_customers(){
      return this.supplierCustomerTypes.filter(f => f.checked == true).length > 0;
    },
    async validate_supplier_cities(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.currentSupplierId}/getSupplierCities`);
        let cities = res.data.cities;
        let states = res.data.states;       
        return cities.length > 0 || states.length > 0;
      } catch (error) {
        return false;
      }
    },
    validate_supplier_testimonies(){
      return this.$refs.supplier_testimonies_component[0].validate();
    },
    validate_personal_profile_tins(){
      if(this.$refs.personal_profile_tins_component[0] != null){
        return this.$refs.personal_profile_tins_component[0].validate();
      }
      else {
        return true;
      }
    },
    validate_business_profile_tins(){
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    async validate_personal_unique_curp(){
     return await this.validate_unique_curp(this.personal.curp, this.personal.id)
    },
    async validate_owner_unique_curp(){
     return await this.validate_unique_curp(this.businessOwner.curp, this.businessOwner.id)
    },
    async validate_personal_clabe_is_valid(){
      return this.validate_clabe(this.personalBankAccount.clabe, this.collections.banks);
    },
    async validate_business_clabe_is_valid(){
      return this.validate_clabe(this.businessBankAccount.clabe, this.collections.banks);
    },
    async validate_owner_clabe_is_valid(){
      return this.validate_clabe(this.businessOwner.bank_accounts[0].clabe, this.collections.banks);
    },
    async validate_personal_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.personal.rfc, this.personal.id)
    },
    async validate_pm_unique_rfc(){
      return await this.validate_unique_business_rfc(this.business.rfc, this.business.id)
    },
    async validate_owner_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.businessOwner.rfc, this.businessOwner.id)
    },
    needsToShowDocumentsSection(section){
      if(this.isMoral){
        return section.data.id === 13 ? false : true;
      } else {
        return section.data.id === 6 ? false : true;
      }
    },
    needsToShowSaveButton(slug){
      return slug === "bank" ? false : true;
    },
    async validate_bank_accounts_special(){
      try {
        let action_needed = this.isBackoffice ? 2 : 1;
        let id = this.currentSupplierUserId;
        const res = await axios.get(`/api/v1/supplier/userBankAccounts/${action_needed}/${id}/verify`)
        this.bankAccountsValidation = res.data;
        let allComplete = true;

        if(res.data.totalAccounts < 1) {
          return false;
        }

        if(!this.bankAccountsValidation.accountsComplete){
          allComplete = false;
        }

        return allComplete;
      } catch(e){
        this.warn(e);
      }
      return false;
    },
    async validate_bank_accounts_special_error(){
      if(!this.bankAccountsValidation){
        return "Información pendiente";
      }

      if(this.bankAccountsValidation.totalAccounts < 1){
        return "Debe agregar al menos 1 cuenta bancaria para realizar movimientos dentro de la plataforma."
      } else {
        if(!this.bankAccountsValidation.accountsComplete){
          return "Debe completar la información de por lo menos una cuenta bancaria. (Cuenta CLABE y carátula)"
        }
      }
    },
    async validate_identity_verification(){
      this.status = await this.getUserIdentityStatus(this.currentSupplierUserId);
      this.identityIsCompleted = this.verificationIsCompleted(this.status);

      return this.identityIsCompleted;
    },
    async validate_identity_verification_error(){
      if(!this.identityIsCompleted){
        return "La verificación de identidad con Mati no ha sido completada.";
      }
    },
    getPersonalFullName(personal){
      let name = personal.first_name;
      if(personal.second_name !== null){
        name += ` ${personal.second_name}`;
      }
      name += ` ${personal.last_name_1}`
      if(personal.last_name_2 !== null){
        name += ` ${personal.last_name_2}`
      }

      return name;
    },
    runAddresses() {
      if(this.isMoral){
        this.set_zip_code_values_business_fiscal_address();
        this.set_zip_code_values_business_address();
        this.set_zip_code_business_owner_personal_address();
      } else {
        this.set_zip_code_personal_business_owner_address();
        this.set_zip_code_values_personal_address();
        this.set_zip_code_values_personal_fiscal_address();
      }
    },

    verify_zip_code_business_fiscal_address() {
      this.verifyZipCode(this.business.fiscal_address, {
        zipCodeVariable: 'zip_code_business_fiscal_address', 
        statesArray: 'states_business_fiscal_address', 
        citiesArray: 'cities_business_fiscal_address', 
        neighborhoodsArray: 'neighborhoods_business_fiscal_address'
      });
    },
    set_zip_code_values_business_fiscal_address() {
      this.setZipCodeAddressValues(this.business.fiscal_address_id, this.business.fiscal_address, {
        zipCodeVariable: 'zip_code_business_fiscal_address',
        statesArray: 'states_business_fiscal_address',
        citiesArray: 'cities_business_fiscal_address',
        neighborhoodsArray: 'neighborhoods_business_fiscal_address'
      });
    },

    verify_zip_code_business_address() {
      this.verifyZipCode(this.business.address, {
        zipCodeVariable: 'zip_code_business_address', 
        statesArray: 'states_business_address', 
        citiesArray: 'cities_business_address', 
        neighborhoodsArray: 'neighborhoods_business_address'
      });
    },
    set_zip_code_values_business_address() {
      this.zip_code_business_fiscal_address = '';
      this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
        zipCodeVariable: 'zip_code_business_address',
        statesArray: 'states_business_address',
        citiesArray: 'cities_business_address',
        neighborhoodsArray: 'neighborhoods_business_address'
      });
    },

    verify_zip_code_personal_address() {
      this.verifyZipCode(this.personal.business_address, {
        zipCodeVariable: 'zip_code_personal_address', 
        statesArray: 'states_personal_address', 
        citiesArray: 'cities_personal_address', 
        neighborhoodsArray: 'neighborhoods_personal_address'
      });
    },
    set_zip_code_values_personal_address() {
      this.zip_code_personal_fiscal_address = '';
      this.setZipCodeAddressValues(this.personal.business_address_id, this.personal.business_address, {
        zipCodeVariable: 'zip_code_personal_address',
        statesArray: 'states_personal_address',
        citiesArray: 'cities_personal_address',
        neighborhoodsArray: 'neighborhoods_personal_address'
      });
    },
    
    verify_zip_code_personal_fiscal_address() {
      this.verifyZipCode(this.personal.fiscal_address, {
        zipCodeVariable: 'zip_code_personal_fiscal_address', 
        statesArray: 'states_personal_fiscal_address', 
        citiesArray: 'cities_personal_fiscal_address', 
        neighborhoodsArray: 'neighborhoods_personal_fiscal_address'
      });
    },
    set_zip_code_values_personal_fiscal_address() {
      this.setZipCodeAddressValues(this.personal.fiscal_address_id, this.personal.fiscal_address, {
        zipCodeVariable: 'zip_code_personal_fiscal_address',
        statesArray: 'states_personal_fiscal_address',
        citiesArray: 'cities_personal_fiscal_address',
        neighborhoodsArray: 'neighborhoods_personal_fiscal_address'
      });
    },

    verify_zip_code_business_owner_personal_address() {
      this.verifyZipCode(this.business.personal.address, {
        zipCodeVariable: 'zip_code_business_owner_personal_address', 
        statesArray: 'states_business_owner_personal_address', 
        citiesArray: 'cities_business_owner_personal_address', 
        neighborhoodsArray: 'neighborhoods_business_owner_personal_address'
      });
    },
    set_zip_code_business_owner_personal_address() {
      this.setZipCodeAddressValues(this.business.personal.address_id, this.business.personal.address, {
        zipCodeVariable: 'zip_code_business_owner_personal_address',
        statesArray: 'states_business_owner_personal_address',
        citiesArray: 'cities_business_owner_personal_address',
        neighborhoodsArray: 'neighborhoods_business_owner_personal_address'
      });
    },

     verify_zip_code_personal_business_owner_address() {
      this.verifyZipCode(this.personal.address, {
        zipCodeVariable: 'zip_code_personal_business_owner_address', 
        statesArray: 'states_personal_business_owner_address', 
        citiesArray: 'cities_personal_business_owner_address', 
        neighborhoodsArray: 'neighborhoods_personal_business_owner_address'
      });
    },
    set_zip_code_personal_business_owner_address() {
      this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
        zipCodeVariable: 'zip_code_personal_business_owner_address',
        statesArray: 'states_personal_business_owner_address',
        citiesArray: 'cities_personal_business_owner_address',
        neighborhoodsArray: 'neighborhoods_personal_business_owner_address'
      });
    }

  }
}
</script>
<style scoped>
  .vuesax-app-is-ltr .vs-tabs--btn-icon+span {
    font-weight: 600 !important;
  }
  .rg-title {
    font-size: 3rem !important;
  }

  .list-customized {
    list-style-type: disc;
    margin-left: 2rem;
  }
</style>