<template>
    <div>
        <vx-card title="Mantén actualizada tu información." class="mb-6">
            <div class="vx-col xxl:w-3/4 w-full">
                <p>
                    Debido a la Reforma Fiscal 2022, para la emisión de comprobante <b>(CFDI)</b> es importante que 
                    registres tus datos fiscales tal y como aparecen en tu <b>Constancia de Situación Fiscal</b> 
                    la cual puedes consultar en el 
                    <a class="inline-link-primary bold" target="_blank" rel="noopener" :href="$sanitizeUrl(satUrl)">SAT</a>.
                </p>
            </div>
        </vx-card>
        <vx-card>
            <user-tax-profile />
        </vx-card>
        <vx-card class="mt-6">
            <div class="vx-col xxl:w-3/4 w-full">
                <li class="bold">
                    Confirmo que los datos que proporciono son veraces y corresponden a mi persona, los cuales servirán para emitir los comprobantes fiscales (CFDI).
                </li>
                <li class="bold">
                    Acepto que, si esta información no concuerda con la que cuenta el SAT, RedGirasol emita los comprobantes sin efectos fiscales.
                </li>
            </div>
        </vx-card>
    </div>
</template>
  
<script>
import UserTaxProfile from '@components/profile_shared/UserTaxProfile';

export default {
    name: 'SupplierTaxProfile',
    components: {
        UserTaxProfile
    },
    data() {
        return {
            satUrl: 'https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal.'
        }
    },
    methods: {
    }
}
</script>
  