<template>
	<div>
		<div class="vx-row mb-4">
			<div class="vx-col w-1/2">
				<label class="vs-input--label">País *</label>
				<v-select
					label="text"
					class="vs-custom w-full"
					:class="{ 'has-error': validation.country != null }"
					v-model.lazy="addressModel.country_id"
					:options="getCountriesList"
					:reduce="text => text.value"
					:clearable="false"
					:searchable="true"
					:disabled="!getCountriesList || !getCountriesList.length"
					placeholder="Seleccione una opción">
					<div slot="no-options">No hay coincidencias</div> 
				</v-select>
				<v-error v-if="validation.country != null" :error="validation.country" />
			</div>
			<div class="vx-col w-1/2">
				<vs-input
					class="w-full" 
					label="Calle *"
					placeholder="Requerido"
					:danger="validation.street != null"
					:danger-text="validation.street"
					v-model.lazy="addressModel.street" />
			</div>
		</div>
		<div class="vx-row mb-4">
			<div class="vx-col w-1/2">
				<vs-input
					class="w-full" 
					label="Número exterior *"
					placeholder="Requerido"
					:danger="validation.ext_number != null"
					:danger-text="validation.ext_number"
					v-model.lazy="addressModel.ext_number" />
			</div>
			<div class="vx-col w-1/2">
				<vs-input
					class="w-full" 
					label="Número interior"
					placeholder="Opcional"
					:danger="validation.int_number != null"
					:danger-text="validation.int_number"
					v-model.lazy="addressModel.int_number" />
			</div>
		</div>
		<div class="vx-row mb-4">
			<div class="vx-col w-1/2" v-if="!isAddressForeign">
				<vs-input
					class="w-full" 
					label="Código postal *"
					type="number"
					placeholder="Requerido"
					:danger="validation.zipcode != null"
					:danger-text="validation.zipcode"
					v-model.lazy="addressModel.zipcode" />
			</div>
			<div class="vx-col w-1/2" v-else>
				<vs-input
					class="w-full" 
					label="Código postal (Extranjero) *"
					placeholder="Requerido"
					:danger="validation.foreign_zipcode != null"
					:danger-text="validation.foreign_zipcode"
					v-model.lazy="addressModel.foreign_zipcode" />
			</div>
			<div class="vx-col w-1/2" v-if="!isAddressForeign">
				<label class="vs-input--label">Estado *</label>
				<v-select
					label="text"
					class="vs-custom w-full"
					:class="{ 'has-error': validation.state != null }"
					v-model.lazy="addressModel.state_id"
					:options="statesList"
					:reduce="text => text.value"
					:clearable="false"
					:searchable="true"
					:disabled="!statesList || !statesList.length"
					placeholder="Seleccione una opción">
					<div slot="no-options">No hay coincidencias</div> 
				</v-select>
				<v-error v-if="validation.state != null" :error="validation.state" />
			</div>
			<div class="vx-col w-1/2" v-else>
				<vs-input
					class="w-full" 
					label="Estado (Extranjero) *"
					placeholder="Requerido"
					:danger="validation.other_state != null"
					:danger-text="validation.other_state"
					v-model.lazy="addressModel.other_state" />
			</div>
		</div>
		<div class="vx-row mb-4">
			<div class="vx-col w-1/2" v-if="!isAddressForeign">
				<label class="vs-input--label">Ciudad o población *</label>
				<v-select
					label="text"
					class="vs-custom w-full"
					:class="{ 'has-error': validation.city != null }"
					v-model.lazy="addressModel.city_id"
					:options="citiesList"
					:reduce="text => text.value"
					:clearable="false"
					:searchable="true"
					:disabled="!citiesList || !citiesList.length"
					placeholder="Seleccione una opción">
					<div slot="no-options">No hay coincidencias</div> 
				</v-select>
				<v-error v-if="validation.city != null" :error="validation.city" />
			</div>
			<div class="vx-col w-1/2" v-else>
				<vs-input
					class="w-full" 
					label="Ciudad o población (Extranjero) *"
					placeholder="Requerido"
					:danger="validation.other_city != null"
					:danger-text="validation.other_city"
					v-model.lazy="addressModel.other_city" />
			</div>
			<div class="vx-col w-1/2" v-if="!isAddressForeign">
				<label class="vs-input--label">Colonia o urbanización *</label>
				<v-select
					label="text"
					class="vs-custom w-full"
					:class="{ 'has-error': validation.neighborhood != null }"
					v-model.lazy="addressModel.neighborhood_id"
					:options="neighborhoodsList"
					:reduce="text => text.value"
					:clearable="false"
					:searchable="true"
					:disabled="!neighborhoodsList || !neighborhoodsList.length"
					placeholder="Seleccione una opción">
					<div slot="no-options">No hay coincidencias</div> 
				</v-select>
				<v-error v-if="validation.neighborhood != null" :error="validation.neighborhood" />
			</div>
			<div class="vx-col w-1/2" v-else>
				<vs-input
					class="w-full" 
					label="Colonia o urbanización (Extranjero) *"
					placeholder="Requerido"
					:danger="validation.foreign_neighborhood != null"
					:danger-text="validation.foreign_neighborhood"
					v-model.lazy="addressModel.foreign_neighborhood" />
			</div>
		</div>
		<div class="vx-row mb-4">
			<div class="vx-col w-1/2">
				<vs-input
					class="w-full" 
					label="Delegación o municipio"
					placeholder="Opcional"
					:danger="validation.municipality != null"
					:danger-text="validation.municipality"
					v-model.lazy="addressModel.municipality" />
			</div>
		</div>
    </div>
</template>

<script>
export default {
    name: 'address-control',
	props: {
		addressModel: { type: Object, required: true },
		countriesList: { type: Array, required: true, default: [] }
	},
	data() {
		return {
			statesList: [],
			citiesList: [],
			neighborhoodsList: [],
			zipCodeTimer: null,
			validation: {}
		}
	},
	watch: {
		'addressModel.zipcode': function() {
			this.validation.zipcode = null;
			this.addressModel.state_id = null;
			this.statesList = [];
			this.addressModel.city_id = null;
			this.citiesList = [];
			this.addressModel.neighborhood_id = null;
			this.neighborhoodsList = [];

			if(this.zipCodeTimer != null)
				clearTimeout(this.zipCodeTimer);
			this.zipCodeTimer = setTimeout(() => this.getNeighborhoodsList(), 1000);
		}
	},
	computed: {
		getValidation(field) {
			return this.validation[field];
		},
		isAddressForeign() {
			return this.addressModel.country_id != 700;
		},
		getCountriesList() {
			return this.countriesList.map((country) => ({ value: country.id, text: country.name }));
		}
	},
	methods: {
		validate() {
			let isCorrectlyValidated = true;
			this.validation = {};

			if(this.addressModel.country_id === null) {
				this.validation.country = 'El país es requerido';
				isCorrectlyValidated = false;
			}
			if(this.addressModel.street === null || this.addressModel.street === '') {
				this.validation.street = 'La calle es requerida';
				isCorrectlyValidated = false;
			}
			if(this.addressModel.ext_number === null || this.addressModel.ext_number === '') {
				this.validation.ext_number = 'El numero exterior es requerido';
				isCorrectlyValidated = false;
			}

			if(this.isAddressForeign) {
				if(this.addressModel.foreign_zipcode === null || this.addressModel.foreign_zipcode === '') {
					this.validation.foreign_zipcode = 'El código postal es requerido';
					isCorrectlyValidated = false;
				}
				if(this.addressModel.other_state === null || this.addressModel.other_state === '') {
					this.validation.other_state = 'El estado es requerido';
					isCorrectlyValidated = false;
				}
				if(this.addressModel.other_city === null || this.addressModel.other_city === '') {
					this.validation.other_city = 'La ciudad es requerida';
					isCorrectlyValidated = false;
				}
				if(this.addressModel.foreign_neighborhood === null || this.addressModel.foreign_neighborhood === '') {
					this.validation.foreign_neighborhood = 'La colonia es requerida';
					isCorrectlyValidated = false;
				}
				return isCorrectlyValidated;
			}

			if(this.addressModel.zipcode === null || this.addressModel.zipcode === '') {
				this.validation.zipcode = 'El código postal es requerido';
				isCorrectlyValidated = false;
			}
			if(this.addressModel.state_id === null || this.addressModel.state_id === 0) {
				this.validation.state = 'El estado es requerido';
				isCorrectlyValidated = false;
			}
			if(this.addressModel.city_id === null || this.addressModel.city_id === 0) {
				this.validation.city = 'La ciudad es requerida';
				isCorrectlyValidated = false;
			}
			if(this.addressModel.neighborhood_id === null || this.addressModel.neighborhood_id === 0) {
				this.validation.neighborhood = 'La colonia es requerida';
				isCorrectlyValidated = false;
			}
			return isCorrectlyValidated;
		},
		async getNeighborhoodsList() {
			try {
				if(isNaN(this.addressModel.zipcode)) {
					this.validation.zipcode = 'El código postal ingresado no es válido';
					return;
				}
				if(!this.addressModel.zipcode || !this.addressModel.zipcode.length) {
					this.validation.zipcode = 'El código postal ingresado no es válido';
					return;
				}
				
				const {data} = await axios.get(`api/getAddressByZipCode/${this.addressModel.zipcode}`);
				const {state, city, neighborhoods} = data;

				if(neighborhoods.length) {
					this.statesList = [{ value: state.id, text: state.name }];
					this.addressModel.state_id = state.id;

					this.citiesList = [{ value: city.id, text: city.name }];
					this.addressModel.city_id = city.id;

					this.neighborhoodsList = neighborhoods.map((neighborhood) => ({ value: neighborhood.id, text: neighborhood.name }));
					return;
				}
			} 
			catch (error) {
				console.log(error);
				this.warn(error);
			}
			this.validation = { zipcode: 'El código postal no tuvo resultados' };
		}
	}
}
</script>
