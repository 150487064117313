<template>
  <div class="skinny row" v-if="visible">
    <div class="alert alert-success alert-dismissible col-md-10" role="alert">
      <button type="button" class="close" aria-label="Close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>{{mTitle}}</strong> {{mMessage}}
    </div>
  </div>
</template>

<script>

const PREDEFINED = [
  {
    title: "¡Información guardada de forma satisfactoria!",
    mssg: " Continúa actualizando los demás apartados de tu perfil."
  },
  {
    title: "¡Felicidades!",
    mssg: "Has completado al 100% tu perfil, en menos de 24 horas hábiles quedará verificado."
  },
];

export default {
  name: 'success-alert',
  props: ["predefined", "title", "message", "visible"],
  data () {
    return {
    }
  },
  computed: {
    mTitle : function () {
      return (this.predefined != null) ? PREDEFINED[this.predefined].title : this.title;      
    },
    mMessage : function () {
      return (this.predefined != null) ? PREDEFINED[this.predefined].mssg : this.message;
    },
  }
}
</script>