<template>
	<div class="vx-col lg:w-1/2 md:w-3/4 sm:w-full w-full mb-5">

		<p class="mb-4 my-0">Indícanos las sucursales adicionales con las que cuentes.</p>
		<vs-button color="primary" type="border" icon-pack="feather" class="mb-4"  @click="isModalShown = true">Agregar sucursal adicional</vs-button>

      	<vs-list v-if="branchesList.length > 0">
			<vs-list-header title="Listado de sucursales"/>
				<vs-list-item 
					v-for="(branch, index) in branchesList"
					:key="index"
					:title="branch.name || 'N/A'"
					:subtitle="branch.address.full_address">               
          			<feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="handleDeleteRequest(branch)" />
        	</vs-list-item>
		</vs-list>

		<vs-popup title="Agregar sucursal adicional" :active.sync="isModalShown">
			<div>
				<div class="vx-row">
					<div class="vx-col w-full">
						<p class="mb-5 bold">A continuación por favor indica el nombre de la sucursal y su dirección</p>
					</div>
				</div>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-input
							class="w-full mb-4" 
							label="Nombre del modelo"
							placeholder="Opcional"
							v-model.lazy="branchName" />
					</div>
				</div>
				<div class="vx-row">
					<div class="vx-col w-full">
						<p class="mb-5 bold">Dirección:</p>
					</div>
				</div>
				<address-control
					ref="addressControl"
					:addressModel="addressModel"
					:countriesList="countriesList"
				/>
				<div class="vx-row mt-10">
					<vs-button class="vx-col w-full mx-4" color="success" icon-pack="feather" @click="handleSaveBranch">Agregar sucursal</vs-button>
				</div>
			</div>
		</vs-popup>

	</div>
</template>

<script>
import AddressControl from '../../address/AddressControl.vue';

export default {
    name: 'supplier-branches-control',
	props: {
		supplierId: { type: Number, required: true },
		countriesList: { type: Array, required: false },
	},
	components: {
		AddressControl
	},
	data() {
		return {
			isModalShown: false,
			branchName: null,
			errorMessage: null,
			branchesList: [],
			selectedBranch: null,
			addressModel: {
				country_id: 700,
				state_id: null,
				city_id: null,
				street: null,
				ext_number: null,
				int_number: null,
				zipcode: null,
				neighborhood_id: null,
				municipality: null,
				other_state: null,
				other_city: null,
				foreign_neighborhood: null,
				foreign_zipcode: null
			}
		}
	},
	async mounted() {
		await this.getBranchesList();
	},
	methods: {
		async getBranchesList() {
			try {
				const {data} = await axios.get(`api/supplier/${this.$props.supplierId}/branches`);
				this.branchesList = data.message;
			}
			catch (error) {
				console.log(error);
				this.warn(error)
			}
		},
		async handleSaveBranch() {
			try {
				const isAddressValidated = this.$refs.addressControl.validate();
				if(isAddressValidated) {
					const {data} = await axios.post(`api/supplier/${this.$props.supplierId}/branch`, {
						branchName: this.branchName,
						...this.addressModel
					});

					if(data.success) {
						await this.getBranchesList();
						this.clearAddressModel();
						this.isModalShown = false;
						return;
					}
				}	
			} 
			catch (error) {
				console.log(error);
				this.warn(error);
			}
		},
		handleDeleteRequest(branch) {
			this.selectedBranch = branch;
			this.$vs.dialog({
				type: 'confirm',
				color: 'danger',
				title: 'Eliminar sucursal',
				text: `¿Realmente desea eliminar la sucursal ${branch.name} — ${branch.address.full_address}?`,
				acceptText: "Eliminar",
				cancelText: 'Cancelar',
				accept: this.handleDeleteBranch
			});
		},
		async handleDeleteBranch() {
			try {
				await axios.delete(`api/supplier/${this.$props.supplierId}/branch/${this.selectedBranch.id}`);
				await this.getBranchesList();
			} 
			catch (error) {
				console.log(error);
				this.warn(error);
			}
		},
		clearAddressModel() {
			this.addressModel = {
				country_id: 700,
				state_id: null,
				city_id: null,
				street: null,
				ext_number: null,
				int_number: null,
				zipcode: null,
				neighborhood_id: null,
				municipality: null,
				other_state: null,
				other_city: null,
				foreign_neighborhood: null,
				foreign_zipcode: null
			};
		}
	}
}
</script>
